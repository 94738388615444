import React from "react";
import { Error, Label, RequiredText, Row } from "./InputGroup.style";
export interface InputGroupProps {
  error?: string;
  label?: string;
  required?: boolean;
  withError?: boolean;
}

export const InputGroup: React.FC<InputGroupProps> = ({
  error,
  label,
  children,
  required = false,
  withError = true,
}) => {
  return (
    <Row>
      {label !== undefined && (
        <Label>
          {label} {required && <RequiredText>*</RequiredText>}
        </Label>
      )}
      {children}
      {withError && error && <Error>{error}</Error>}
    </Row>
  );
};
