/* eslint-disable @typescript-eslint/no-explicit-any */

import { Loader } from "components/atoms";
import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { useAuth } from "services/auth/context";

interface MixedRouteProps extends Omit<RouteProps, "component"> {
  authComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  defaultComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const MixedRoute: React.FC<MixedRouteProps> = ({
  authComponent: AuthComponent,
  defaultComponent: DefaultComponent,
  ...otherProps
}) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Route component={AuthComponent} {...otherProps} />;
  }

  return <Route component={DefaultComponent} {...otherProps} />;
};

export { MixedRoute };
