import { StyledTextField } from "components/atoms";
import { InputGroup } from "components/molecules";
import { REGEX_FRENCH_PHONE_NUMBER } from "constant";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";

interface PhoneNumberFieldProps {
  label?: string;
  withError?: boolean;
}

export const PhoneNumberField: React.FC<FieldProps & PhoneNumberFieldProps> = ({
  field,
  form,
  label,
  withError,
}) => {
  const { value, hasError, error, setValue } = useFormikInput<string>(form, field);

  return (
    <>
      <InputGroup error={error} label={label} withError={withError} required={true}>
        <StyledTextField
          type="text"
          hasError={hasError}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          value={value}
          inputProps={{
            inputMode: "numeric",
            pattern: REGEX_FRENCH_PHONE_NUMBER,
          }}
        />
      </InputGroup>
    </>
  );
};
