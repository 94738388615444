import { apiClient } from "services/networking/apiClient";
import { authenticatedApiClient } from "services/networking/authenticatedApiClient";
import { InstitutionUserDTO, UpdateInstitutionUserDTO } from "types/dto";
import { InstitutionUser } from "types/entity";
import { DOCUMENT_TYPE } from "types/enum";

export const getMeAsInstitutionUser = async (): Promise<InstitutionUser> => {
  const url = `/institution-user/me`;
  const { data: institutionUserDto } = await authenticatedApiClient.get<InstitutionUserDTO>(url);

  return institutionUserDto;
};

export const updateMe = async (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  UpdateInstitutionUserDTO: UpdateInstitutionUserDTO
): Promise<void> => {
  const url = `/institution-user/`;

  await authenticatedApiClient.patch(url, UpdateInstitutionUserDTO);
};

export async function getCareGiverDocument(
  careGiverId: string,
  documentType: DOCUMENT_TYPE
): Promise<string> {
  const url = `/care-giver/${careGiverId}/document/predownload?documentType=${documentType}`;
  const { data } = await authenticatedApiClient.get<{ presignedUrl: string }>(url);

  return data.presignedUrl;
}

export async function getCareGiverApplicationFolder(
  careGiverId: string
): Promise<{ blob: Blob; filename: string }> {
  const url = `/care-giver/${careGiverId}/document/application-folder`;
  const { data, headers } = await authenticatedApiClient.get<ArrayBuffer>(url, {
    responseType: "arraybuffer",
  });

  return {
    blob: new Blob([data], { type: "application/zip" }),
    filename: headers["content-disposition"].split('"')[1],
  };
}

export const updatePassword = async (
  email: string,
  password: string,
  code: string
): Promise<void> => {
  const url = "/users/reset-password";

  return apiClient.post(url, { email, password, code });
};
