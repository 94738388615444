export enum SERVICES {
  EMERGENCY = "emergency",
  ONCOLOGY = "oncology",
  INTENSIVE_CARE = "intensive-care",
  GERIATRICS = "geriatrics",
  GENERAL_CARE = "general-care",
  PSYCHIATRY = "psychiatry",
  NEUROLOGY = "neurology",
  SURGERY = "surgery",
  INTERNAL_MEDICINE = "internal-medicine",
  MATERNITY = "maternity",
  ANESTHESIA = "anesthesia",
  CARDIOLOGY = "cardiology",
  RESUSCITATION = "resuscitation",
  UROLOGY = "urgoly",
  PEDIATRICS = "pediatrics",
  RADIOLOGY = "radiology",
  OTHERS = "others",
}

export const SERVICES_FR = {
  [SERVICES.EMERGENCY]: "Urgence",
  [SERVICES.ONCOLOGY]: "Oncologie",
  [SERVICES.INTENSIVE_CARE]: "Soins intensif",
  [SERVICES.GERIATRICS]: "Gériatrie",
  [SERVICES.GENERAL_CARE]: "Soins généraux",
  [SERVICES.PSYCHIATRY]: "Psychiatrie",
  [SERVICES.NEUROLOGY]: "Neurologie",
  [SERVICES.SURGERY]: "Chrirugie",
  [SERVICES.INTERNAL_MEDICINE]: "Médecine interne",
  [SERVICES.MATERNITY]: "Maternité",
  [SERVICES.ANESTHESIA]: "Anesthésie",
  [SERVICES.CARDIOLOGY]: "Cardiologie",
  [SERVICES.RESUSCITATION]: "Réanimation",
  [SERVICES.UROLOGY]: "Urologie",
  [SERVICES.PEDIATRICS]: "Pédiatrie",
  [SERVICES.RADIOLOGY]: "Radiologie",
  [SERVICES.OTHERS]: "Autres",
};
