import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
Container.displayName = "Container";

export const PageContent = styled.div`
  padding: ${({ theme }) => theme.extend.getSpacing(8)} ${({ theme }) => theme.extend.getSpacing(4)};
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
`;
PageContent.displayName = "PageContent";

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.extend.fontSize.large};
`;
Title.displayName = "Title";

export const HelperList = styled.ul`
  list-style: disc inside;
  margin-top: ${({ theme }) => theme.extend.getSpacing(2)};
  line-height: ${({ theme }) => theme.extend.lineHeight.medium};
`;
HelperList.displayName = "HelperList";
