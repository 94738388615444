const ACCESS_TOKEN_KEY_STORE = "ACCESS_TOKEN";

export function getAccessToken(): string | null {
  return localStorage.getItem(ACCESS_TOKEN_KEY_STORE);
}

export function storeAccessToken(accessToken: string): void {
  localStorage.setItem(ACCESS_TOKEN_KEY_STORE, accessToken);
}

export function clearStore(): void {
  localStorage.removeItem(ACCESS_TOKEN_KEY_STORE);
}
