import styled from "@emotion/styled";
import { TextField, TextFieldProps } from "@mui/material";

interface Props {
  hasError: boolean;
}

export const Input = styled.input<Props>`
  width: fill-available;
  height: 44px;

  background-color: ${({ theme }) => theme.palette.common.white};

  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.medium};
  color: ${({ theme, hasError }) =>
    hasError ? theme.palette.error.main : theme.extend.palette.grey.medium};

  padding: 0 ${({ theme }) => theme.extend.getSpacing(3)};

  border-radius: ${({ theme }) => theme.extend.radius.s};
  border-style: solid;
  border-width: ${({ hasError }) => (hasError ? "1px" : "0px")};
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.palette.error.main : theme.palette.primary.main};

  :hover {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.palette.error.main : theme.palette.primary.main};
  }

  :focus {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.palette.error.main : theme.palette.primary.main};
  }

  ::placeholder {
    color: ${({ theme }) => theme.extend.palette.grey.main};
  }
`;

export const StyledTextField = styled(({ hasError, ...otherProps }: Props & TextFieldProps) => (
  <TextField {...otherProps} />
))`
  width: 100%;
  height: 44px;

  background-color: ${({ theme }) => theme.palette.common.white};

  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.medium};
  color: ${({ theme, hasError }) =>
    hasError ? theme.palette.error.main : theme.extend.palette.grey.medium};

  border-radius: ${({ theme }) => theme.extend.radius.s};
  border-style: solid;
  border-width: ${({ hasError }) => (hasError ? "1px" : "0px")};
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.palette.error.main : theme.palette.primary.main};

  input {
    padding-top: ${({ theme }) => theme.extend.getSpacing(2)};
    padding-bottom: ${({ theme }) => theme.extend.getSpacing(2)};
  }

  :hover {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.palette.error.main : theme.palette.primary.main};
  }

  :focus {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.palette.error.main : theme.palette.primary.main};
  }

  ::placeholder {
    color: ${({ theme }) => theme.extend.palette.grey.main};
  }
`;
