import styled from "@emotion/styled";
import { Card as CardMui } from "@mui/material";

export const Card = styled(CardMui)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.extend.radius.s};
  margin-top: ${({ theme }) => theme.extend.getSpacing(4)};
  margin-bottom: ${({ theme }) => theme.extend.getSpacing(4)};
  padding: ${({ theme }) => theme.extend.getSpacing(4)};
`;
