import styled from "@emotion/styled";
import { TextareaAutosize } from "@mui/material";
import { isMobile } from "react-device-detect";

export const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  resize: none;

  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.medium};
  color: ${({ theme }) => theme.palette.primary.main};

  border-radius: ${({ theme }) => theme.extend.radius.s};
  border-style: solid;
  border: none;

  padding-top: ${({ theme }) => theme.extend.getSpacing(2)};
  padding-bottom: ${({ theme }) => theme.extend.getSpacing(2)};

  padding-left: ${({ theme }) => (!isMobile ? theme.extend.getSpacing(2) : "initial")};
  padding-right: ${({ theme }) => (!isMobile ? theme.extend.getSpacing(2) : "initial")};
`;
