import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import { CloseIcon } from "../HeaderForm/HeaderForm.style";

export const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.dark};
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-size: ${({ theme }) => theme.extend.fontSize.small};

  margin-right: ${({ theme }) => theme.extend.getSpacing(2)} !important;
`;

export const ChipDeleteIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
  margin-right: ${({ theme }) => theme.extend.getSpacing(2)} !important;
`;

export const ChipsContainer = styled.div`
  margin-top: ${({ theme }) => theme.extend.getSpacing(2)};
`;
