import styled from "@emotion/styled";
import { MdOutlineClose } from "react-icons/md";

export const Container = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${({ theme }) => theme.extend.getSpacing(8)};
  margin-bottom: ${({ theme }) => theme.extend.getSpacing(8)};
`;

export const TitleContainer = styled.div`
  display: inline-block;
`;

export const Title = styled.h1`
  width: fit-content;
  border-bottom: solid 1px ${({ theme }) => theme.palette.primary.main};

  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.extend.fontSize.big};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};
  font-family: ${({ theme }) => theme.extend.fontFamily.main};

  padding-bottom: ${({ theme }) => theme.extend.getSpacing(5)};
  padding-left: ${({ theme }) => theme.extend.getSpacing(15)};
  padding-right: ${({ theme }) => theme.extend.getSpacing(15)};
`;

export const CloseLink = styled.div`
  position: fixed;
  right: 10%;
  top: 18vh;
  cursor: pointer;
`;

export const CloseIcon = styled(MdOutlineClose)`
  color: ${({ theme }) => theme.palette.primary.main};
  vertical-align: sub;
  margin-right: ${({ theme }) => theme.extend.getSpacing(1)};
`;

export const CloseText = styled.span`
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-size: ${({ theme }) => theme.extend.fontSize.regular};
  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
`;

export const SubTitle = styled.p`
  margin-top: ${({ theme }) => theme.extend.getSpacing(4)};

  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.extend.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.extend.fontSize.regular};
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.dark};
`;
