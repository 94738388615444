import { Loader } from "components/atoms";
import { PATHS } from "constant";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "services/auth/context";

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: PATHS.LOGIN,
        }}
      />
    );
  }

  return <Route {...props} />;
};

export { ProtectedRoute };
