import { InstitutionAnnounceDTO } from ".";
import { Application } from "../entity";
import { CareGiverDTO } from "./careGiver";
import {
  convertInstitutionAnnounceDTOToEntity,
  convertInstitutionAnnounceToDTO,
} from "./institutionAnnounce";

export interface ApplicationDTO extends Omit<Application, "careGiver" | "announce" | "createdAt"> {
  careGiver: CareGiverDTO;
  announce: InstitutionAnnounceDTO;
  createdAt: string;
}

export const convertApplicationDTOToEntity = (applicationDto: ApplicationDTO): Application => ({
  ...applicationDto,
  announce: convertInstitutionAnnounceDTOToEntity(applicationDto.announce),
  createdAt: new Date(applicationDto.createdAt),
});

export const convertApplicationToDTO = (application: Application): ApplicationDTO => ({
  ...application,
  announce: convertInstitutionAnnounceToDTO(application.announce),
  createdAt: application.createdAt.toISOString(),
});
