import logoColorSVG from "assets/logo-color.png";
import { LinkExternal } from "components/atoms";
import React from "react";
import {
  HeaderContainer,
  Logo,
  LogoContainer,
  PhoneContainer,
  PhoneIcon,
  PhoneText,
} from "./Header.style";

const HeaderMobile: React.FC = () => (
  <HeaderContainer>
    <LogoContainer>
      <LinkExternal href="https://clickandcare.fr">
        <Logo alt="Click&Care logo" src={logoColorSVG} />
      </LinkExternal>
      <PhoneContainer>
        <PhoneIcon size={12} />
        <PhoneText>09 78 38 38 38</PhoneText>
      </PhoneContainer>
    </LogoContainer>
  </HeaderContainer>
);

export { HeaderMobile };
