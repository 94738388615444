/* istanbul ignore file */

import jwt_decode from "jwt-decode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { USER_TYPES } from "types/enum";
import client, { UserTokenInfo } from "./api";
import { getAccessToken } from "./store.web";

// Request a new access token this many seconds prior to expiration
const REFRESH_TOKEN_EXPIRATION_BUFFER = 5 * 1000;

interface AuthContextType {
  login(email: string, password: string): void;
  signUp(email: string, password: string, type: USER_TYPES): void;
  logout(): void;
  user: UserTokenInfo | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: unknown | null;
  loginError: unknown | null;
  signUpError: unknown | null;
  resetErrors(): void;
}
interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<AuthContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signUp: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  user: null,
  isLoading: false,
  isAuthenticated: false,
  error: null,
  loginError: null,
  signUpError: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetErrors: () => {},
});

export const useAuth = (): AuthContextType => useContext(AuthContext);

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useState<null | UserTokenInfo>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | unknown>(null);
  const [loginError, setLoginError] = useState<null | unknown>(null);
  const [signUpError, setSignUpError] = useState<null | unknown>(null);

  const handleError = useCallback(async (err: unknown, setErrorState: (error: unknown) => void) => {
    setIsLoading(false);
    setErrorState(err);
    setUser(null);
    console.error(err);
  }, []);

  const handleSuccess = useCallback(
    async (accessToken: string) => {
      const userTokenInfo = jwt_decode<UserTokenInfo>(accessToken);

      setUser(userTokenInfo);
      setError(null);
      setLoginError(null);

      if (process.env.REACT_APP_ENV !== "test") {
        handleTokenExpiration(userTokenInfo);
      }
      setIsLoading(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleTokenExpiration = useCallback(
    async (userTokenInfo: UserTokenInfo) => {
      const expiresIn = userTokenInfo.exp * 1000 - new Date().getTime();

      setTimeout(() => {
        tryUseRefreshToken();
      }, expiresIn - REFRESH_TOKEN_EXPIRATION_BUFFER);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tryUseRefreshToken = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const response = await client.refreshToken();

        handleSuccess(response);
      } catch (err) {
        handleError(err, setError);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      const initAuth = async () => {
        const accessToken = await getAccessToken();

        if (!accessToken) {
          setIsLoading(false);

          return;
        }

        const userTokenInfo = jwt_decode<UserTokenInfo>(accessToken);

        if (
          new Date().getTime() > userTokenInfo.exp * 1000 - REFRESH_TOKEN_EXPIRATION_BUFFER &&
          process.env.REACT_APP_ENV !== "test"
        ) {
          tryUseRefreshToken();
        } else {
          handleSuccess(accessToken);
        }
      };

      initAuth();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(async () => {
    await client.logout();
    setUser(null);
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        setIsLoading(true);
        const response = await client.login({ email, password });

        handleSuccess(response);
      } catch (err) {
        handleError(err, setLoginError);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signUp = useCallback(
    async (email: string, password: string, type: USER_TYPES) => {
      try {
        setIsLoading(true);
        const response = await client.signUp({ email, password, type });

        handleSuccess(response);
      } catch (err) {
        handleError(err, setSignUpError);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetErrors = useCallback(() => {
    setLoginError(null);
    setError(null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        signUp,
        user,
        isLoading,
        isAuthenticated: user !== null,
        error,
        loginError,
        signUpError,
        resetErrors,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
