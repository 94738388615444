import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FiTrash2 } from "react-icons/fi";

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.extend.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.extend.fontSize.regular};
  color: ${({ theme }) => theme.palette.common.black};

  margin-top: ${({ theme }) => theme.extend.getSpacing(1)};
`;

export const DeleteAnnounceLink = styled(Button)`
  float: right;
  padding-top: ${({ theme }) => theme.extend.getSpacing(1)};

  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
  color: ${({ theme }) => theme.palette.error.main};
  text-decoration: underline;
  text-transform: inherit;

  :hover {
    text-decoration: underline;
  }
`;

export const DeleteAnnounceIcon = styled(FiTrash2)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-right: ${({ theme }) => theme.extend.getSpacing(3)};
`;

type Props = {
  hasSeparator: boolean;
};

export const Container = styled.div<Props>`
  margin-bottom: ${({ theme }) => theme.extend.getSpacing(6)};
  padding-top: ${({ theme }) => theme.extend.getSpacing(4)};

  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.extend.palette.grey.main};
  border-width: ${({ hasSeparator }) => (hasSeparator ? "1px" : "0px")};
`;
