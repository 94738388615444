/* istanbul ignore file */

import { USER_TYPES } from "../../types/enum";
import { apiClient } from "../networking/apiClient";
import { clearStore, storeAccessToken } from "./store.web";

export type LoginParams = {
  email: string;
  password: string;
};

export type SignUpParams = LoginParams & {
  type: USER_TYPES;
};

export type UserTokenInfo = {
  user_id: string;
  type: string;
  email: string;
  roles: string[];
  exp: number;
};

export type Token = {
  access: string;
};

export class Client {
  async login(data: LoginParams): Promise<string> {
    const { data: result }: { data: Token } = await apiClient.post("/auth/jwt/create", data, {
      withCredentials: true,
    });

    if (result.access) {
      storeAccessToken(result.access);
    }

    return result.access;
  }

  async signUp(data: SignUpParams): Promise<string> {
    await apiClient.post("/users", data);

    return this.login({ email: data.email, password: data.password });
  }

  async logout(): Promise<unknown> {
    const { data } = await apiClient.post("/auth/jwt/logout");

    clearStore();

    return data;
  }

  async refreshToken(): Promise<string> {
    const { data: result }: { data: Token } = await apiClient.post(
      "/auth/jwt/refresh",
      {},
      {
        withCredentials: true,
      }
    );

    if (result.access) {
      storeAccessToken(result.access);
    }

    return result.access;
  }
}

const client = new Client();

export default client;
