/* stylelint-disable scale-unlimited/declaration-strict-value */

import styled from "@emotion/styled";

export const titleH1 = styled.h1`
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.large};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};
  line-height: "21px";
`;

export const titleH3 = styled.h3`
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.regular};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};
  line-height: "21px";
`;

export const interractiveCTAText = styled.h3`
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
  line-height: ${({ theme }) => theme.extend.fontSize.regular};
`;

export const textP2Regular = styled.p`
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
`;
