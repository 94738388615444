import Autocomplete from "@mui/material/Autocomplete";
import { InputGroup } from "components/molecules";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";
import { Certificate } from "types/entity";
import { StyledAutocompleteTextField } from "./CertificatePicker.style";

interface CertificatePickerProps {
  options: Certificate[];
  label?: string;
  withError?: boolean;
}

const INCLUDE_CERTIFICATE_CODE = ["DEAS", "DEAVS", "IDE", "ASH", "AU"];

export const CertificatePicker: React.FC<FieldProps & CertificatePickerProps> = ({
  field,
  form,
  options,
  label,
  withError,
}) => {
  const { value, hasError, error } = useFormikInput<number>(form, field);

  const isOptionEqualToValue = (
    certificateOption: unknown,

    // eslint-disable-next-line @typescript-eslint/no-shadow
    value: unknown
  ) => {
    if (value === undefined) {
      return false;
    }

    return (certificateOption as Certificate).id === (value as Certificate).id;
  };

  const getOptionLabel = (certificateOption: unknown) => {
    const certificate = certificateOption as Certificate;

    if (certificate.name === "Autre") {
      return "Autre";
    }

    return `${certificate.name} - ${certificate.code}`;
  };

  return (
    <InputGroup error={error} label={label} withError={withError} required={true}>
      <Autocomplete
        onChange={(
          _event,

          // eslint-disable-next-line @typescript-eslint/no-shadow
          value
        ) => {
          form.setFieldValue(field.name, value !== null ? (value as Certificate).id : value);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        options={options.filter((certificate) =>
          INCLUDE_CERTIFICATE_CODE.includes(certificate.code)
        )}
        value={options.find((option) => option.id === value) ?? null}
        renderInput={(params) => <StyledAutocompleteTextField hasError={hasError} {...params} />}
      />
    </InputGroup>
  );
};
