import { Autocomplete } from "@mui/material";
import { InputGroup } from "components/molecules";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";
import { CONTRACT_TYPES_FR, INSTITUTION_CONTRACT_TYPE } from "types/enum";
import { StyledAutocompleteTextField } from "../CertificatePicker/CertificatePicker.style";

interface ContractTypePickerProps {
  label?: string;
  withError?: boolean;
}

export const ContractTypePicker: React.FC<FieldProps & ContractTypePickerProps> = ({
  field,
  form,
  label,
  withError,
}) => {
  const { value, hasError, error } = useFormikInput<INSTITUTION_CONTRACT_TYPE>(form, field);

  return (
    <>
      <InputGroup error={error} label={label} withError={withError} required={true}>
        <Autocomplete
          onChange={(
            _event,

            // eslint-disable-next-line @typescript-eslint/no-shadow
            value
          ) => {
            form.setFieldValue(field.name, value);
          }}
          getOptionLabel={(option: INSTITUTION_CONTRACT_TYPE) => CONTRACT_TYPES_FR[option]}
          options={Object.values(INSTITUTION_CONTRACT_TYPE)}
          value={value ?? null}
          renderInput={(params) => <StyledAutocompleteTextField hasError={hasError} {...params} />}
        />
      </InputGroup>
    </>
  );
};
