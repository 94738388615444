/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable scale-unlimited/declaration-strict-value */

import styled from "@emotion/styled";
import { titleH3 } from "../Common";

export const SideBarContainer = styled.div`
  width: ${({ theme }) => theme.extend.getSpacing(70)};
  height: 100%;

  background-color: ${({ theme }) => theme.palette.common.white};

  z-index: ${({ theme }) => theme.zIndex.drawer};
  position: fixed;
  left: 0;
`;

export const SideBarItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;

  padding-left: ${({ theme }) => theme.extend.getSpacing(8)};
  padding-top: ${({ theme }) => theme.extend.getSpacing(7)};
  padding-bottom: ${({ theme }) => theme.extend.getSpacing(7)};

  ${({ isActive, theme }) =>
    isActive && {
      borderLeft: `8px solid ${theme.palette.primary.main}`,
    }}

  ${({ isActive, theme }) =>
    !isActive && {
      marginLeft: theme.extend.getSpacing(2),
    }}

  &:nth-of-type(1) {
    margin-top: ${({ theme }) => theme.extend.getSpacing(6)};
  }

  h3 {
    color: ${({ isActive, theme }) =>
      isActive ? theme.palette.primary.main : theme.extend.palette.grey.medium};
  }

  div > svg {
    color: ${({ isActive, theme }) =>
      isActive ? theme.palette.primary.main : theme.extend.palette.grey.main};
  }
`;

export const SideBarLogo = styled.div`
  font-size: ${({ theme }) => theme.extend.fontSize.large};
  vertical-align: middle;
`;

export const SideBarText = styled(titleH3)`
  margin-left: ${({ theme }) => theme.extend.getSpacing(5)};
  align-items: center;

  color: ${({ theme }) => theme.palette.primary.main};
  line-height: ${({ theme }) => theme.extend.lineHeight.medium};
`;
