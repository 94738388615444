import logoColor from "assets/logo-color.png";
import { LinkExternal } from "components/atoms";
import { LinkHeader } from "components/molecules";
import React from "react";
import { useAuth } from "services/auth/context";
import {
  HeaderContainer,
  LinkContainer,
  Logo,
  LogoContainer,
  PhoneContainer,
  PhoneIcon,
  PhoneText,
} from "./Header.style";

const HeaderDesktop: React.FC = () => {
  const { logout, isAuthenticated } = useAuth();

  return (
    <HeaderContainer>
      <LogoContainer>
        <LinkExternal href="https://clickandcare.fr">
          <Logo alt="Click&Care logo" src={logoColor} />
        </LinkExternal>
        <PhoneContainer>
          <PhoneIcon size={12} />
          <PhoneText>09 78 38 38 38</PhoneText>
        </PhoneContainer>
      </LogoContainer>

      <LinkContainer>
        <LinkHeader isAuthenticated={isAuthenticated} onLogout={logout} />
      </LinkContainer>
    </HeaderContainer>
  );
};

export { HeaderDesktop };
