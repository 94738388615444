import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextFieldProps } from "@mui/material/TextField";
import { StyledTextField } from "components/atoms";
import { InputGroup } from "components/molecules";
import frLocale from "date-fns/locale/fr";
import { FieldProps } from "formik";
import { useFormikInput, useFormikInputByName } from "hook";
import { get } from "lodash";
import React from "react";
import { INSTITUTION_CONTRACT_TYPE } from "types/enum";
import { StyledDatePicker } from "./DatePicker.style";

export interface DatePickerProps extends FieldProps {
  label?: string;
  withError?: boolean;
  required?: boolean;
  minDate?: Date;
  minDateField?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  field,
  form,
  label,
  withError,
  required,
  minDate,
  minDateField,
}) => {
  const baseField = field.name.split(".")[0];

  const { value, hasError, error } = useFormikInput<number>(form, field);
  const { value: contractType } = useFormikInputByName<INSTITUTION_CONTRACT_TYPE>(
    form,
    `${baseField}.contractType`
  );

  if (field.name === `${baseField}.endDate` && contractType === INSTITUTION_CONTRACT_TYPE.CDI) {
    return <></>;
  }

  let minDateValue = undefined;

  if (minDate !== undefined) {
    minDateValue = minDate;
  }
  if (minDateField !== undefined) {
    minDateValue = get(form.values, minDateField);
  }

  return (
    <InputGroup
      error={error as string | undefined}
      label={label}
      required={required}
      withError={withError}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <StyledDatePicker
          minDate={minDateValue}
          mask="__/__/____"
          value={value ?? null}
          onChange={(date: unknown) => {
            form.setFieldValue(field.name, date as Date);
          }}
          InputAdornmentProps={{ position: "start", variant: "standard" }}
          renderInput={(params) => <DatePickerTextField hasError={hasError} {...params} />}
        />
      </LocalizationProvider>
    </InputGroup>
  );
};

const DatePickerTextField: React.FC<TextFieldProps & { hasError: boolean }> = ({
  hasError,
  ...otherProps
}) => {
  delete otherProps.inputProps?.placeholder;

  return <StyledTextField hasError={hasError} {...{ ...otherProps, placeholder: "" }} />;
};
