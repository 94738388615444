import { ThemeProvider } from "@mui/material/styles";
import { AppCrashFallback } from "components/molecules";
import { ErrorBoundary, Root } from "components/organisms";
import { BackdropProvider } from "context";
import { SnackbarProvider } from "notistack";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Routes from "routes";
import { AuthProvider } from "services/auth/context";
import { theme } from "utils/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary FallbackComponent={AppCrashFallback}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          autoHideDuration={1500}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <BackdropProvider>
            <AuthProvider>
              <BrowserRouter>
                <Root>
                  <Routes />
                </Root>
              </BrowserRouter>
            </AuthProvider>
          </BackdropProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
