import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { AiOutlineUser } from "react-icons/ai";

export const LinkUserIcon = styled(AiOutlineUser)`
  vertical-align: sub;
  margin-right: ${({ theme }) => theme.extend.getSpacing(2)};
`;

export const LinkStyled = styled(Button)`
  background: none;
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: unset;
  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
`;
