import { apiClient } from "services/networking/apiClient";

export async function sendSecurityCode(email: string): Promise<void> {
  const url = `/email-verification/reset-password`;

  await apiClient.post<void>(url, { email });
}

export async function checkSecurityCode(email: string, code: string): Promise<void> {
  const url = `/email-verification/check`;

  await apiClient.post<void>(url, { email, code });
}
