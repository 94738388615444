import styled from "@emotion/styled";
import { Link as MuiLink } from "@mui/material";

interface ILink extends Record<string, unknown> {
  href?: string;
  to?: string;
  disabled?: boolean;
}

export const Link = styled.a<ILink>`
  padding: ${({ theme }) => theme.extend.getSpacing(2)} ${({ theme }) => theme.extend.getSpacing(2)};

  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: inherit;
  line-height: ${({ theme }) => theme.extend.lineHeight.medium};
  text-decoration: none;
  color: ${({ theme, disabled = false }) =>
    disabled ? theme.extend.palette.grey.pale : theme.palette.primary.main};

  transition: color 0.3s ease-in-out;
  cursor: ${({ disabled = false }) => (disabled ? "default" : "pointer")};
  pointer-events: ${({ disabled = false }) => (disabled ? "initial" : "none")};

  border: none;
  border-radius: ${({ theme }) => theme.extend.radius.m};

  :hover {
    color: ${({ theme, disabled = false }) =>
      disabled ? theme.extend.palette.grey.pale : theme.palette.primary.dark};
  }
`;

interface InternalLinkProps extends Record<string, unknown> {
  disabled?: boolean;
}

export const LinkInternal = styled(MuiLink)<InternalLinkProps>`
  font-family: ${({ theme }) => theme.extend.fontFamily.main};

  color: ${({ theme, disabled = false }) =>
    disabled ? theme.extend.palette.grey.pale : theme.palette.primary.main};

  :hover {
    color: ${({ theme, disabled = false }) =>
      disabled ? theme.extend.palette.grey.pale : theme.palette.primary.dark};
  }
`;
