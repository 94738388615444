import { Grid } from "@mui/material";
import { AnnouncesStepFormValues } from "components/template";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import {
  Container,
  DeleteAnnounceIcon,
  DeleteAnnounceLink,
  Title,
} from "./HeaderAnnounceForm.style";

interface HeaderAnnounceFormProps {
  index: number;
  onDeleteAnnounce: () => void;
}

export const HeaderAnnounceForm: React.FC<HeaderAnnounceFormProps> = ({
  index,
  onDeleteAnnounce: propsOnDeleteAnnounce,
}) => {
  const { setFieldValue } = useFormikContext<AnnouncesStepFormValues>();

  useEffect(() => {
    if (index === 0) {
      return;
    }
    setTimeout(() => {
      document.getElementById(`announce-header-${index}`)?.scrollIntoView({
        behavior: "smooth",
      });
    }, 150);
  }, [index]);

  const onDeleteAnnounce = () => {
    setFieldValue(`[${index}]`, undefined);
    propsOnDeleteAnnounce();
  };

  return (
    <Container hasSeparator={index > 0} id={`announce-header-${index}`}>
      <Grid container>
        <Grid item md={3}>
          <Title>Besoin {index + 1}</Title>
        </Grid>
        <Grid item md={6} />
        {index > 0 && (
          <Grid item md={3}>
            <DeleteAnnounceLink onClick={onDeleteAnnounce}>
              <DeleteAnnounceIcon fontSize={14} /> Supprimer
            </DeleteAnnounceLink>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
