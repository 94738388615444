/* stylelint-disable declaration-no-important */

import styled from "@emotion/styled";
import { Col as ColGrid, Row as RowGrid } from "react-grid-system";

export const Col = styled(ColGrid)`
  padding-right: 0 !important;
  padding-left: 0 !important;
`;

export const Row = styled(RowGrid)`
  margin-right: 0 !important;
  margin-left: 0 !important;
`;
