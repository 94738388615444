import Autocomplete from "@mui/material/Autocomplete";
import { getInsitutionGouvOption, InstitutionGouvOption } from "api";
import { InputGroup } from "components/molecules";
import { REGEX_POST_CODE } from "constant";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { StyledAutocompleteTextField } from "./InstitutionPicker.style";

interface InstitutionPickerProps {
  label?: string;
  withError?: boolean;
}

export const InstitutionPicker: React.FC<FieldProps & InstitutionPickerProps> = ({
  field,
  form,
  label,
  withError,
}) => {
  const [options, setOptions] = useState<InstitutionGouvOption[]>([]);
  const { value, hasError, error, setValue } = useFormikInput<InstitutionGouvOption | null>(
    form,
    field
  );

  useEffect(() => {
    if (value === undefined || value === null || isEmpty(value)) {
      return;
    }
    getInsitutionGouvOption(value.siret).then(setOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = async (_event: unknown, newValue: string) => {
    const institutionOptions = await getInsitutionGouvOption(newValue);

    setOptions(institutionOptions);
  };

  const isOptionEqualToValue = (
    institutionOption: InstitutionGouvOption,

    // eslint-disable-next-line @typescript-eslint/no-shadow
    value: InstitutionGouvOption | undefined
  ) => {
    if (value === undefined) {
      return false;
    }

    return institutionOption.siret === value.siret;
  };

  const getOptionLabel = (institutionOption: InstitutionGouvOption) => {
    return `${institutionOption.name}, ${institutionOption.address.city}`;
  };

  const filterOptions = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    options: InstitutionGouvOption[],
    { inputValue }: { inputValue: string }
  ) => {
    if (REGEX_POST_CODE.test(inputValue)) {
      return options.filter((option) => option.address.postCode === inputValue);
    }

    return options;
  };

  return (
    <InputGroup error={error} label={label} withError={withError} required={true}>
      <Autocomplete
        autoComplete
        includeInputInList
        filterOptions={filterOptions}
        onChange={(
          _event,

          // eslint-disable-next-line @typescript-eslint/no-shadow
          value
        ) => {
          setValue(value);
        }}
        onInputChange={onInputChange}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        options={options}
        value={value}
        renderInput={(params) => (
          <StyledAutocompleteTextField
            placeholder="Entrer le code postal de votre établissement"
            hasError={hasError}
            {...params}
          />
        )}
      />
    </InputGroup>
  );
};
