import { FieldProps } from "formik";
import { useFormikInput, useFormikInputByName } from "hook";
import React, { useEffect, useState } from "react";
import { Certificate } from "types/entity";
import { INSTITUTION_CONTRACT_TYPE } from "types/enum";
import { TextField, TextFieldProps } from "../TextField";

const TEMPORARY_HOUR_SALARY: Record<string, number> = {
  DEAS: 23.9,
  DEAVS: 23.9,
  IDE: 29.9,
  ASH: 23.9,
};

type HourSalaryFieldProps = FieldProps &
  TextFieldProps & {
    certificates: Certificate[];
  };

const HourSalaryField: React.FC<HourSalaryFieldProps> = (props) => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const { field, form, certificates } = props;

  const baseField = field.name.split(".")[0];

  const { value: contractType } = useFormikInputByName<INSTITUTION_CONTRACT_TYPE>(
    form,
    `${baseField}.contractType`
  );
  const { value: certificateId } = useFormikInputByName<number>(form, `${baseField}.certificateId`);
  const { value: hourSalary, setValue: setHourSalaryValue } = useFormikInput<number | string>(
    form,
    field
  );

  const certificate = certificates.find(
    (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      certificate
    ) => certificate.id === certificateId
  );

  useEffect(
    () => {
      const isLocalReadOnly =
        contractType === INSTITUTION_CONTRACT_TYPE.TEMPORARY &&
        certificate !== undefined &&
        certificate.name !== "Autre";

      if (isLocalReadOnly && hourSalary !== TEMPORARY_HOUR_SALARY[certificate.code]) {
        setHourSalaryValue(TEMPORARY_HOUR_SALARY[certificate.code]);
      }

      setIsReadOnly(isLocalReadOnly);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [certificate, contractType, hourSalary, isReadOnly]
  );

  useEffect(
    () => {
      if (!isReadOnly) {
        setHourSalaryValue("");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReadOnly]
  );

  return <TextField {...props} disabled={isReadOnly} />;
};

export { HourSalaryField };
