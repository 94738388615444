import React from "react";
import { isMobile } from "react-device-detect";
import { HeaderDesktop } from "./Header.desktop";
import { HeaderMobile } from "./Header.mobile";

const Header: React.FC = () => {
  if (isMobile) {
    return <HeaderMobile />;
  }

  return <HeaderDesktop />;
};

export { Header };
