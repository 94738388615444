import { authenticatedApiClient } from "services/networking/authenticatedApiClient";
import {
  convertInstitutionAnnounceDTOToEntity,
  CreateInstitutionAnnounceDTO,
  CreateInstitutionLeadWithInstitutionAndAccountDTO,
  InstitutionAnnounceDTO,
} from "types/dto";
import { InstitutionAnnounce } from "types/entity";

export async function getAnnouncesByInstitution(): Promise<InstitutionAnnounce[]> {
  const url = `/announces/institution`;
  const { data: institutionAnnouncesDto } = await authenticatedApiClient.get<
    InstitutionAnnounceDTO[]
  >(url);

  return institutionAnnouncesDto.map(convertInstitutionAnnounceDTOToEntity);
}
export async function getInstitutionAnnounceById(id: string): Promise<InstitutionAnnounce> {
  const url = `/announces/${id}`;
  const { data: institutionAnnounceDto } = await authenticatedApiClient.get<InstitutionAnnounceDTO>(
    url
  );

  return convertInstitutionAnnounceDTOToEntity(institutionAnnounceDto);
}

export function createInstitutionAnnounce(
  createAnnounceDto: CreateInstitutionAnnounceDTO
): Promise<void> {
  const url = `/leads/institution`;

  return authenticatedApiClient.post(url, createAnnounceDto);
}

export async function createInstitutionLeadWithInstitutionAndAccount(
  createInstitutionLeadWithInstitutionAndAccountDto: CreateInstitutionLeadWithInstitutionAndAccountDTO
) {
  const url = `/leads/institutions/public`;
  const { data } = await authenticatedApiClient.post<{ id: string }>(
    url,
    createInstitutionLeadWithInstitutionAndAccountDto
  );

  return data.id;
}

export function updateInstitutionLead(
  id: string,
  updateInstitutionLeadDto: CreateInstitutionAnnounceDTO
): Promise<{ id: string }> {
  const url = `/leads/institutions/${id}`;

  return authenticatedApiClient.patch(url, updateInstitutionLeadDto);
}

export async function getApplicationsFolder(
  announceId: string
): Promise<{ blob: Blob; filename: string }> {
  const url = `/announces/${announceId}/applications-folder`;
  const { data, headers } = await authenticatedApiClient.get<ArrayBuffer>(url, {
    responseType: "arraybuffer",
  });

  return {
    blob: new Blob([data], { type: "application/zip" }),
    filename: headers["content-disposition"].split('"')[1],
  };
}
