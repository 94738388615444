import { authenticatedApiClient } from "services/networking/authenticatedApiClient";
import { CertificateDTO } from "types/dto";
import { Certificate } from "types/entity";

export async function getAllCertificates(): Promise<Certificate[]> {
  const url = `/certificates`;
  const { data: certificatesDto } = await authenticatedApiClient.get<CertificateDTO[]>(url);

  return certificatesDto;
}
