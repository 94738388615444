import styled from "@emotion/styled";
import { Button } from "components/atoms/Button/Button.style";
import { BsCheckCircleFill } from "react-icons/bs";

export const GenderUnselectedButton = styled(Button)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};

  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.dark};

  padding-top: ${({ theme }) => theme.extend.getSpacing(3)};
  padding-bottom: ${({ theme }) => theme.extend.getSpacing(3)};

  &:hover {
    background-color: ${({ theme }) => theme.extend.palette.blue.dark};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const GenderSelectedButton = styled(GenderUnselectedButton)`
  background-color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    background-color: ${({ theme }) => theme.extend.palette.blue.dark};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const CheckIcon = styled(BsCheckCircleFill)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
