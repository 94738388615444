import { CircularProgress } from "@mui/material";
import { Backdrop } from "components/atoms";
import { BackdropContextType } from "hook";
import React, { createContext, FC, useState } from "react";

export const BackdropContext = createContext<BackdropContextType>({
  setBackdropEnable: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: boolean

    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
});

interface BackdropProviderProps {
  children: React.ReactNode;
}

export const BackdropProvider: FC<BackdropProviderProps> = ({ children }) => {
  const [backdropEnable, setBackdropEnable] = useState(false);

  return (
    <BackdropContext.Provider
      value={{
        setBackdropEnable,
      }}
    >
      <Backdrop open={backdropEnable}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
};
