import { authenticatedApiClient } from "services/networking/authenticatedApiClient";
import { ApplicationDTO, convertApplicationDTOToEntity } from "types/dto";
import { Application } from "types/entity";

export async function getApplicationByInstitutionAnnounce(id: string): Promise<Application[]> {
  const url = `/applications/announce/${id}`;
  const { data: applicationsDto } = await authenticatedApiClient.get<ApplicationDTO[]>(url);

  return applicationsDto.map(convertApplicationDTOToEntity);
}

export async function getApplications(): Promise<Application[]> {
  const url = `/applications`;
  const { data: applicationsDto } = await authenticatedApiClient.get<ApplicationDTO[]>(url);

  return applicationsDto.map(convertApplicationDTOToEntity);
}

export async function validateApplication(id: string): Promise<void> {
  const url = `/applications/${id}/validate`;

  await authenticatedApiClient.post(url);
}

export async function refuseApplication(id: string): Promise<void> {
  const url = `/applications/${id}/refuse`;

  await authenticatedApiClient.post(url);
}
