export enum DOCUMENT_TYPE {
  CNI_VERSO = "cni-verso",
  CNI_RECTO = "cni-recto",
  CV = "cv",
  CERTIFICATE = "certificate",
  WORK_CERTIFICATE = "work-certificate",
  CARTE_VITALE = "carte-vitale",
  PROOF_OF_RESIDENCE = "proof-of-residence",
  CRIMINAL_RECORD = "criminal-record",
  DRIVERS_LICENSE = "drivers-license",
  CAR_INSURANCE = "car-insurance",
  LETTER_OF_RECOMMENDATION = "letter-of-recommendation",
}
