import { useFormikInputByName } from "hook";
import React from "react";
import { INSTITUTION_CONTRACT_TYPE } from "types/enum";
import { DatePicker, DatePickerProps } from "../DatePicker";

const EndDatePicker: React.FC<DatePickerProps> = (props) => {
  const { field, form } = props;

  const baseField = field.name.split(".")[0];

  const { value: contractType } = useFormikInputByName<INSTITUTION_CONTRACT_TYPE>(
    form,
    `${baseField}.contractType`
  );

  if (contractType === INSTITUTION_CONTRACT_TYPE.CDI) {
    return <></>;
  }

  return <DatePicker {...props} />;
};

export { EndDatePicker };
