import { ButtonProps } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { NextGradientButton } from "../StepForm";

export const SubmitFormikButton: React.FC<ButtonProps> = ({ children, ...otherProps }) => {
  const { isValid, handleSubmit } = useFormikContext();

  return (
    <NextGradientButton
      disabled={!isValid}
      onClick={() => {
        handleSubmit();
      }}
      {...otherProps}
    >
      {children}
    </NextGradientButton>
  );
};
