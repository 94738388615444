import { Input } from "components/atoms";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";
import { InputGroup } from "../../molecules";

interface InputRowProps extends FieldProps {
  type: string;
  disabled?: boolean;
  placeholder?: string;
  withError?: boolean;
}

export const InputRow: React.FC<InputRowProps> = ({
  type,
  disabled,
  placeholder,
  form,
  field,
  withError = false,
  ...otherProps
}) => {
  const { error } = useFormikInput(form, field);

  return (
    <InputGroup {...otherProps} error={error} withError={withError}>
      <Input
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        {...field}
        hasError={withError && error !== undefined}
      />
    </InputGroup>
  );
};
