/* stylelint-disable scale-unlimited/declaration-strict-value */

import styled from "@emotion/styled";
import { Button as ButtonMui } from "@mui/material";

interface StyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor: string;
  textColor: string;
  borderColor?: string;
  borderRadius: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  height: 45px;
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ theme }) => theme.extend.getSpacing(3)} ${({ theme }) => theme.extend.getSpacing(3)};
  color: ${({ textColor }) => textColor};
  background: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: ${({ theme }) => theme.extend.fontSize.big};
  }
  :hover {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }

  ${({ borderColor }) =>
    borderColor
      ? `
    border-width: 1px;
    border-color: ${borderColor};
  `
      : `
    border: none;
  `}
`;

export const ButtonContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.medium};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};
  margin-right: ${({ theme }) => theme.extend.getSpacing(4)};
  margin-left: ${({ theme }) => theme.extend.getSpacing(4)};
`;

export const GradientButton = styled(ButtonMui)`
  background: ${({ disabled, theme }) =>
    disabled ? theme.extend.palette.gradient.grey.main : theme.extend.palette.gradient.blue.main};

  color: ${({ disabled, theme }) =>
    disabled
      ? theme.extend.palette.gradient.grey.contrastText
      : theme.extend.palette.gradient.blue.contrastText};

  border-radius: ${({ theme }) => theme.extend.radius.s};
  text-transform: unset;
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
`;

export const OutlineButton = styled(ButtonMui)`
  border-color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.extend.radius.s};
  text-transform: unset;
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
`;

export const Button = styled(ButtonMui)`
  text-transform: unset;
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
`;
