import styled from "@emotion/styled";

export const ErrorBlockContainer = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.extend.radius.s};
  padding: ${({ theme }) => theme.extend.getSpacing(2)} ${({ theme }) => theme.extend.getSpacing(4)};
  background-color: ${({ theme }) => theme.palette.error.light};
  margin-bottom: ${({ theme }) => theme.extend.getSpacing(5)};
`;

export const ErrorBlockText = styled.p`
  color: ${({ theme }) => theme.extend.palette.grey.medium};
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
  line-height: ${({ theme }) => theme.extend.fontSize.medium};
`;
