import { useSwitch, UseSwitchProps } from "@mui/material";
import React from "react";
import { SwitchInput, SwitchRoot, SwitchThumb, SwitchTrack } from "./Switch.style";

const clsx = ({
  checked,
  disabled,
  focusVisible,
}: {
  checked: boolean;
  disabled: boolean;
  focusVisible: boolean;
}) => {
  if (checked) {
    return "checked";
  }
  if (disabled) {
    return "disabled";
  }
  if (focusVisible) {
    return "focusVisible";
  }
};

export const Switch: React.FC<UseSwitchProps> = (props) => {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };

  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack className={clsx(stateClasses)}>
        <SwitchThumb className={clsx(stateClasses)} />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} />
    </SwitchRoot>
  );
};
