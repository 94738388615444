import { showReportDialog } from "@sentry/browser";
import { Button } from "components/atoms/Button/Button.style";
import React from "react";
import { Container, HelperList, PageContent, Title } from "./AppCrashFallback.style";

/**
 * Error page inspiration https://medium.com/design-ideas-thoughts/designing-error-pages-8d82e16e3472
 */
export interface IFallbackProps {
  eventId: string;
}

const reportDialog = (eventId: string) => () => showReportDialog({ eventId });

export const AppCrashFallback: React.FunctionComponent<IFallbackProps> = ({ eventId }) => {
  return (
    <main>
      <Container>
        <PageContent>
          <Title>Désolé, cela ne fonctionne pas correctement.</Title>
          <br />
          <p>Nous sommes au courant de ce problème et nous travaillons à le résoudre.</p>
          <br />
          <p>En attendant, voici ce que vous pouvez faire :</p>
          <HelperList>
            <li>Rafraîchissez la page (cela peut parfois aider).</li>
            <li>Essayez à nouveau dans 30 minutes.</li>
            <li>
              <Button color="primary" variant="contained" onClick={reportDialog(eventId)}>
                Dites nous ce qu&apos;il s&apos;est passé
              </Button>
            </li>
          </HelperList>
        </PageContent>
      </Container>
    </main>
  );
};
