import styled from "@emotion/styled";

interface ILinkExternal {
  href?: string;
  to?: string;
}

export const LinkExternal = styled.a<ILinkExternal>`
  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: inherit;
  line-height: ${({ theme }) => theme.extend.lineHeight.medium};
  text-decoration: "underline";
  color: ${({ theme }) => theme.palette.primary.main};
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.extend.radius.m};

  :hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
