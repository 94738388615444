import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import App from "./App";

const SENTRY_DSN = "https://95b48261bc2945d4b0d6b5d96c97edc9@o960083.ingest.sentry.io/6152683";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  debug: process.env.REACT_APP_ENV !== "prod",
  enabled:
    process.env.REACT_APP_ENV !== undefined &&
    ["prod", "preprod"].includes(process.env.REACT_APP_ENV),
});

const rootEl = document.getElementById("root");

if (rootEl) {
  ReactDOM.render(<App />, rootEl);
}

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default; // eslint-disable-line
    if (rootEl) {
      ReactDOM.render(<NextApp />, rootEl);
    }
  });
}
