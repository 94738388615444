import axios from "axios";
import { InstitutionGouvData } from "types/dto";
import { Address, Institution } from "types/entity";

export interface InstitutionGouvOption extends Omit<Institution, "id" | "contact" | "address"> {
  address: Omit<Address, "gpsLat" | "gpsLng" | "id">;
}

const CATEGETAB_LIST_TO_EXCLUDE = [
  156, 161, 366, 412, 415, 425, 430, 444, 126, 426, 695, 696, 697, 698, 699, 603, 604, 142, 143,
  266, 267, 268, 269, 270, 294, 347, 438, 223, 224, 228, 230, 231, 433, 124, 125, 130, 289, 439,
  636, 610, 611, 612, 620, 624, 627, 628, 629, 632, 690, 132, 630, 631, 633, 159, 166, 172, 175,
  176, 177, 236, 411, 241, 427, 441, 286, 295, 344, 418, 453, 378, 440, 214, 216, 219, 442, 443,
  256, 257, 271, 380, 165, 175, 180, 197, 213, 258, 259, 340, 341, 342, 464, 300, 330, 374, 436,
];

export const getInsitutionGouvOption = async (query: string): Promise<InstitutionGouvOption[]> => {
  const searchParams = new URLSearchParams();

  searchParams.set("dataset", "finess-etablissements");
  searchParams.set("q", query);
  searchParams.set("lang", "fr");
  searchParams.set("rows", "100");

  CATEGETAB_LIST_TO_EXCLUDE.forEach((codeCatToExclude) => {
    searchParams.append("exclude.categetab", codeCatToExclude.toString());
  });

  const { data: institutionsData } = await axios.get<InstitutionGouvData>(
    `https://public.opendatasoft.com/api/records/1.0/search/?${searchParams.toString()}`
  );

  return institutionsData.records
    .filter((record) => record.fields.siret !== undefined)
    .map((record) => ({
      name: record.fields.rs,
      siret: record.fields.siret as string,
      address: {
        address: record.fields.address,
        postCode: record.fields.com_code,
        city: formatLibelleCommune(record.fields.com_name),
      },
    }));
};

// Format Tours 9 => Tours, Paris 13 => Paris
const formatLibelleCommune = (libelleCommune: string): string => {
  const REGEX_HAS_NUMBER = /\s\d/;

  if (!REGEX_HAS_NUMBER.test(libelleCommune)) {
    return libelleCommune;
  }

  return libelleCommune.split(/(\s\d+)/)[0];
};
