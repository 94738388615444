import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { getMeAsInstitutionUser } from "api";
import { Link } from "components/atoms";
import { PATHS } from "constant";
import React, { useState } from "react";
import { MdOutlineLogout } from "react-icons/md";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { LinkStyled, LinkUserIcon } from "./LinkHeader.style";

export type LinkHeaderProps = {
  isAuthenticated: boolean;
  onLogout: () => void;
};

type LinkHeaderAuthenticatedProps = {
  onLogout: () => void;
};

const LinkHeaderAuthenticated: React.FC<LinkHeaderAuthenticatedProps> = ({ onLogout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: institutionUser } = useQuery(["meAsInstitutionUser"], () =>
    getMeAsInstitutionUser()
  );

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(null);
  };

  return (
    <>
      <LinkStyled
        id="header-menu-btn"
        aria-controls="header-menu"
        aria-haspopup="true"
        aria-expanded={open}
        variant="text"
        onClick={handleOpenMenu}
      >
        <LinkUserIcon size={24} />
        Espace de {institutionUser?.person?.firstName} {institutionUser?.person?.lastName}
      </LinkStyled>
      <Menu
        id="header-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "header-menu-btn",
        }}
      >
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <MdOutlineLogout fontSize="normal" />
          </ListItemIcon>
          <ListItemText>Se déconnecter</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export const LinkHeader: React.FC<LinkHeaderProps> = ({ isAuthenticated, onLogout }) => {
  if (isAuthenticated) {
    return <LinkHeaderAuthenticated onLogout={onLogout} />;
  }

  return (
    <Link as={RouterLink} to={PATHS.LOGIN}>
      <LinkUserIcon size={24} />
      Espace établissement
    </Link>
  );
};
