import styled from "@emotion/styled";
import { Checkbox, Grid } from "@mui/material";
import { GradientButton, OutlineButton } from "components/atoms/Button/Button.style";
import { isMobile } from "react-device-detect";
import { TiArrowLeft, TiArrowRight } from "react-icons/ti";

export const Form = styled.div`
  margin-top: ${({ theme }) => theme.extend.getSpacing(8)};
`;

export const Row = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.extend.getSpacing(8)};
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

export const CheckboxLabel = styled.h4`
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
  font-weight: ${({ theme }) => theme.extend.fontWeight.regular};
  color: ${({ theme }) => theme.extend.palette.grey.medium};
`;

export const NextGradientButton = styled(GradientButton)`
  padding-right: ${({ theme }) => theme.extend.getSpacing(10)};
  padding-left: ${({ theme }) => theme.extend.getSpacing(10)};
  padding-top: ${({ theme }) => theme.extend.getSpacing(2)};
  padding-bottom: ${({ theme }) => theme.extend.getSpacing(2)};
`;

export const ValidateGradientButton = styled(GradientButton)`
  padding-right: ${({ theme }) => theme.extend.getSpacing(5)};
  padding-left: ${({ theme }) => theme.extend.getSpacing(5)};
  padding-top: ${({ theme }) => theme.extend.getSpacing(2)};
  padding-bottom: ${({ theme }) => theme.extend.getSpacing(2)};

  margin-left: ${({ theme }) => (!isMobile ? theme.extend.getSpacing(4) : "initial")};
`;

export const BackGradientButton = styled(OutlineButton)`
  padding-right: ${({ theme }) => theme.extend.getSpacing(10)};
  padding-left: ${({ theme }) => theme.extend.getSpacing(10)};
  padding-top: ${({ theme }) => theme.extend.getSpacing(2)};
  padding-bottom: ${({ theme }) => theme.extend.getSpacing(2)};
`;

export const LeftArrowIcon = styled(TiArrowLeft)`
  margin-right: ${({ theme }) => theme.extend.getSpacing(2)};
`;

export const RightArrowIcon = styled(TiArrowRight)`
  margin-left: ${({ theme }) => theme.extend.getSpacing(2)};
`;
