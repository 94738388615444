export const convertDateToISOString = (date: Date | undefined): string | undefined => {
  if (date !== undefined) {
    return date.toISOString();
  }

  return undefined;
};

export const convertISOStringToDate = (date: string | undefined): Date | undefined => {
  if (date !== undefined && date !== null) {
    return new Date(date);
  }

  return undefined;
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
