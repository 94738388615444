import styled from "@emotion/styled";
import { StyledTextField } from "components/atoms";

export const StyledAutocompleteTextField = styled(StyledTextField)`
  input {
    padding-top: ${({ theme }) => theme.extend.getSpacing(1)} !important;
    padding-bottom: ${({ theme }) => theme.extend.getSpacing(0)} !important;
  }

  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
