import { FC } from "react";
import { LoaderContainer, LoaderWrapper } from "./Loader.style";

// Loader from https://loading.io/css/
type Color = "white" | "default";

interface LoaderProps {
  color?: Color;
}

export const Loader: FC<LoaderProps> = ({ color = "default" }) => (
  <LoaderWrapper data-testid="Loader">
    <LoaderContainer color={color}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoaderContainer>
  </LoaderWrapper>
);
