/**
 * We force the typing because typescript does not know our environment
 */
export const Environment = {
  apiUrl: process.env.REACT_APP_API_BASE_URL as string,
  nodeEnv: process.env.REACT_APP_ENV as string,
};

if (!Environment.apiUrl) {
  console.error("Api URL not defined ! Check your environement variables");
}

if (!Environment.nodeEnv) {
  console.error("Node env not defined ! Check your environement variables");
}
