import { createTheme } from "@mui/material/styles";

const MEASUREMENT_UNIT = "px";

export const theme = createTheme({
  palette: {
    primary: {
      dark: "#04263F",
      main: "#387F95",
      light: "#D1E6ED",
    },
    secondary: {
      dark: "#924A3A",
      main: "#F1A594",
      light: "#F6CFC6",
    },
    background: {
      default: "#F1F5F8",
    },
    error: {
      main: "#d44949",
      light: "#ea9999",
      dark: "#9b2c2c",
    },
    warning: {
      main: "#dca02d",
      light: "#f5e086",
      dark: "#8c490b",
    },
    success: {
      main: "#48ae65",
      light: "#ccf0d6",
      dark: "#1a6558",
    },
  },
  extend: {
    palette: {
      gradient: {
        blue: {
          main: "linear-gradient(90deg, #71C2C0 -27.6%, #088098 111.77%)",
          contrastText: "white",
        },
        grey: {
          main: "linear-gradient(90deg, #CFCCCC -27.6%, #838687 111.77%)",
          contrastText: "white",
        },
        red: {
          main: "linear-gradient(92.31deg, #FEA190 1.14%, #D74242 109.46%)",
          contrastText: "white",
        },
      },
      grey: {
        dark: "#252323",
        medium: "#4F5B64",
        main: "#4F5B64",
        light: "#FFF9F3",
        pale: "#f1f5f8",
        white: "#FFFFFF",
      },
      blue: {
        dark: "#03505D",
      },
    },
    shadows: {
      main: {
        color: "#52616429",
        radius: "10px",
        offset: { width: "2px", height: "2px" },
        opacity: 0.16,
        elevation: "4px",
      },
    },
    fontFamily: {
      main: "Montserrat",
      secondary: "Lato",
    },
    fontSize: {
      xxl: "60px",
      xl: "40px",
      big: "32px",
      large: "24px",
      regular: "18px",
      medium: "16px",
      small: "14px",
      xs: "12px",
    },
    fontWeight: {
      extraBold: 800,
      bold: 700,
      large: 600,
      regular: 400,
      light: 300,
    },
    lineHeight: {
      xl: "48px",
      large: "36px",
      medium: "24px",
      regular: "18px",
      small: "12px",
    },
    radius: {
      xs: "4px",
      s: "8px",
      m: "16px",
      xxl: "40px",
    },
    spacing: 4,
    getSpacing: (multiplier: number): string => `${multiplier * 4}${MEASUREMENT_UNIT}`,
  },
});
