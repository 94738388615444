import styled from "@emotion/styled";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SectionContainer = styled.div`
  width: auto;
  margin-left: ${({ theme }) => theme.extend.getSpacing(70)};
  height: fit-content;
`;

export const PageContent = styled.main`
  flex-grow: 1;
  margin-top: ${({ theme }) => theme.extend.getSpacing(0.5)};
  height: 90%;
  overflow: auto;
  background-color: ${({ theme }) => theme.extend.palette.grey.pale};
`;

export const SectionContent = styled.div`
  padding: ${({ theme }) => theme.extend.getSpacing(3)};
`;
