import { PATHS } from "constant";
import React from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import {
  CloseIcon,
  CloseLink,
  CloseText,
  Container,
  SubTitle,
  Title,
  TitleContainer,
} from "./HeaderForm.style";

type HeaderFormProps = {
  title: string;
};

export const HeaderForm: React.FC<HeaderFormProps> = ({ title }) => {
  const history = useHistory();

  return (
    <Container>
      <TitleContainer>
        <Title>Je recrute</Title>
        <SubTitle>{title}</SubTitle>
      </TitleContainer>
      {!isMobile && (
        <CloseLink
          onClick={() => {
            history.push(PATHS.MY_ANNOUNCES);
          }}
        >
          <CloseIcon size={20} />
          <CloseText>Quitter</CloseText>
        </CloseLink>
      )}
    </Container>
  );
};
