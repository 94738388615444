import { ButtonProps, Grid } from "@mui/material";
import { InputGroup } from "components/molecules";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";
import { GENDER } from "types/enum";
import { CheckIcon, GenderSelectedButton, GenderUnselectedButton } from "./GenderPicker.style";

interface GenderPickerProps {
  label?: string;
  withError?: boolean;
}

export const GenderPicker: React.FC<FieldProps & GenderPickerProps> = ({
  field,
  form,
  label,
  withError,
}) => {
  const { value, error, setValue } = useFormikInput<GENDER>(form, field);

  return (
    <InputGroup error={error} label={label} withError={withError} required={true}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={6}>
          <GenderButton
            selected={value === GENDER.WOMAN}
            fullWidth={true}
            onClick={() => {
              setValue(GENDER.WOMAN);
            }}
            endIcon={value === GENDER.WOMAN && <CheckIcon />}
          >
            👩 Madame
          </GenderButton>
        </Grid>
        <Grid item md={6} xs={6}>
          <GenderButton
            selected={value === GENDER.MAN}
            fullWidth={true}
            onClick={() => {
              setValue(GENDER.MAN);
            }}
            endIcon={value === GENDER.MAN && <CheckIcon />}
          >
            👨 Monsieur
          </GenderButton>
        </Grid>
      </Grid>
    </InputGroup>
  );
};

interface GenderButtonProps extends ButtonProps {
  selected: boolean;
}

const GenderButton: React.FC<GenderButtonProps> = ({ selected, ...otherProps }) => {
  if (selected) {
    return <GenderSelectedButton {...otherProps} />;
  }

  return <GenderUnselectedButton {...otherProps} />;
};
