import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { StyledTextField } from "components/atoms";
import { InputGroup } from "components/molecules";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";

export type TextFieldProps = MuiTextFieldProps & {
  label?: string;
  withError?: boolean;
};

export const TextField: React.FC<FieldProps & TextFieldProps> = ({
  field,
  form,
  label,
  withError,
  ...otherProps
}) => {
  const { value, hasError, error } = useFormikInput<string>(form, field);

  return (
    <InputGroup error={error} label={label} withError={withError} required={otherProps.required}>
      <StyledTextField
        hasError={hasError}
        onChange={(event) => {
          form.setFieldValue(field.name, event.target.value);
        }}
        value={value ?? ""}
        {...otherProps}
      />
    </InputGroup>
  );
};
