import { TextareaAutosizeProps } from "@mui/material";
import { InputGroup } from "components/molecules";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";
import { StyledTextareaAutosize } from "./AdditionalInformationTextarea.style";

interface AdditionalInformationTextareaProps extends TextareaAutosizeProps {
  label?: string;
}

export const AdditionalInformationTextarea: React.FC<
  FieldProps & AdditionalInformationTextareaProps
> = ({ field, form, label, ...otherProps }) => {
  const { value, error } = useFormikInput<string>(form, field);

  return (
    <InputGroup error={error} label={label}>
      <StyledTextareaAutosize
        {...otherProps}
        onChange={(event) => {
          form.setFieldValue(field.name, event.target.value);
        }}
        value={value}
      />
    </InputGroup>
  );
};
