export enum PATHS {
  HOME = "/",
  MY_ANNOUNCES = "/mes-annonces",
  MY_PARTICIPANTS = "/mes-intervenants",
  LOGIN = "/connexion",
  RESET_PASSWORD = "/reinitialiser-mon-mot-de-passe",
  CREATE_ANNOUNCE = "/creer-une-annonce",
  CHECK_SECURITY_CODE = "/verifier-mon-code-de-securite",
}

export const APP_LAYOUT_PATHS = [PATHS.MY_ANNOUNCES, PATHS.MY_PARTICIPANTS, PATHS.HOME];

// Match HH:mm format string
export const REGEX_MILITARY_TIME = /([01]?[0-9]|2[0-3]):([0-5][0-9])/;
// Match XX.YY, X, 0.XX but not XX.Y
export const REGEX_PRICE = /(0\.\d\d)|(\d*[1-9])|(\d*[1-9])\.(\d\d)/;
// Match 0612344556 , +33655667789
export const REGEX_FRENCH_PHONE_NUMBER = /^((\+)33|0)[1-9](\d{2}){4}$/;
// Match XXXXX
export const REGEX_POST_CODE = /\d{2}[ ]?\d{3}/;
// Match each 2 char
export const REGEX_MATCH_EACH_2_CHAR = /.{1,2}/g;
