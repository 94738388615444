import { getMeAsInstitutionUser } from "api";
import { Loader, SideBar } from "components/atoms";
import { SectionContainer } from "components/organisms/Root/Root.style";
import { PATHS } from "constant";
import { MixedRoute } from "helpers/MixedRoute";
import { ProtectedRoute } from "helpers/ProtectedRoute";
import React, { lazy, Suspense, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { InstitutionUser } from "types/entity";

const Login = lazy(() => import("pages/Login/Login"));
const CreateAnnounceAndAccount = lazy(
  () => import("pages/CreateAnnounceAndAccount/CreateAnnounceAndAccount")
);
const CheckSecurityCode = lazy(() => import("pages/CheckSecurityCode/CheckSecurityCode"));

const MyAnnounces = lazy(() => import("pages/MyAnnounces/MyAnnounces"));
const MyCareGivers = lazy(() => import("pages/MyCareGivers/MyCareGivers"));
const ResetPassword = lazy(() => import("pages/ResetPassword/ResetPassword"));
const MyAnnounce = lazy(() => import("pages/MyAnnounce/MyAnnounce"));
const DuplicateAnnounce = lazy(() => import("pages/NewAnnounce/DuplicateAnnounce"));
const CreateAnnounce = lazy(() => import("pages/NewAnnounce/CreateAnnounce"));

const NewAnnounceSuccess = lazy(() => import("pages/NewAnnounce/Success"));

const MobileRoutes = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route path={`${PATHS.CREATE_ANNOUNCE}/success`} exact component={NewAnnounceSuccess} />
      <Route
        path={[`${PATHS.CREATE_ANNOUNCE}`, PATHS.HOME]}
        exact
        component={CreateAnnounceAndAccount}
      />
    </Switch>
  </Suspense>
);

const DesktopRoutes = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route path={PATHS.LOGIN} component={Login} />
      <Route path={PATHS.CHECK_SECURITY_CODE} exact component={CheckSecurityCode} />
      <Route path={PATHS.RESET_PASSWORD} exact component={ResetPassword} />
      <Route path={`${PATHS.CREATE_ANNOUNCE}/success`} exact component={NewAnnounceSuccess} />
      <MixedRoute
        path={`${PATHS.CREATE_ANNOUNCE}`}
        exact
        defaultComponent={CreateAnnounceAndAccount}
        authComponent={CreateAnnounce}
      />
      <ProtectedRoute path={PATHS.HOME} component={AuthenticatedRoutes} />
    </Switch>
  </Suspense>
);

const Routes: React.FC = () => {
  if (isMobile) {
    return <MobileRoutes />;
  }

  return <DesktopRoutes />;
};

const AuthenticatedRoutes = (): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    getMeAsInstitutionUser().then((me: InstitutionUser) => {
      if (!me.isActive && pathname !== PATHS.RESET_PASSWORD) {
        history.push(PATHS.RESET_PASSWORD);
      }
    });
  }, [history, pathname]);

  return (
    <Switch>
      <Route path={`${PATHS.CREATE_ANNOUNCE}/:announceId`} exact component={DuplicateAnnounce} />
      <Route>
        <SideBar />
        <SectionContainer>
          <Switch>
            <Route path={PATHS.HOME} exact render={() => <Redirect to={PATHS.MY_ANNOUNCES} />} />
            <Route path={PATHS.MY_ANNOUNCES} exact component={MyAnnounces} />
            <Route path={PATHS.MY_PARTICIPANTS} exact component={MyCareGivers} />
            <Route path={`${PATHS.MY_ANNOUNCES}/:announceId`} exact component={MyAnnounce} />
          </Switch>
        </SectionContainer>
      </Route>
    </Switch>
  );
};

export default Routes;
