import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { default as MuiTimePicker } from "@mui/lab/TimePicker";
import { TextFieldProps } from "@mui/material/TextField";
import { StyledTextField } from "components/atoms";
import { InputGroup } from "components/molecules";
import { REGEX_MILITARY_TIME } from "constant";
import frLocale from "date-fns/locale/fr";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import { get } from "lodash";
import React from "react";

interface TimePickerProps {
  label?: string;
  withError?: boolean;
  required?: boolean;
  minTimeFieldName?: string;
  maxTimeFieldName?: string;
}

export const timeStringToUnixDate = (timeString: string): number =>
  Date.parse(`01 Jan 1970 ${timeString}:00 GMT+1`);

export const dateToTimeString = (date: Date): string =>
  `${date.toTimeString().split(":")[0]}:${date.toTimeString().split(":")[1]}`;

export const TimePicker: React.FC<FieldProps & TimePickerProps> = ({
  field,
  form,
  label,
  withError,
  required,
  minTimeFieldName,
  maxTimeFieldName,
}) => {
  const { value, hasError, error } = useFormikInput<string>(form, field);

  const onChange = (date: unknown, textFieldInput: string | undefined) => {
    if (date === null) {
      form.setFieldValue(field.name, null);

      return;
    }

    // Case time = 12:1. Javascript don't throw type error cast it as 12:10 so we have to process this error
    if (textFieldInput !== undefined && !REGEX_MILITARY_TIME.test(textFieldInput)) {
      form.setFieldError(field.name, "Mauvais format d'heure (format attendu hh:mm)");

      return;
    }

    const selecteDate = date as Date;

    form.setFieldValue(field.name, dateToTimeString(selecteDate));
  };

  return (
    <InputGroup error={error} label={label} withError={withError} required={required}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <MuiTimePicker
          mask="__:__"
          minTime={
            minTimeFieldName !== undefined
              ? new Date(timeStringToUnixDate(get(form.values, minTimeFieldName)))
              : undefined
          }
          maxTime={
            maxTimeFieldName !== undefined
              ? new Date(timeStringToUnixDate(get(form.values, maxTimeFieldName)))
              : undefined
          }
          value={timeStringToUnixDate(value)}
          onChange={onChange}
          InputAdornmentProps={{ position: "start", variant: "standard" }}
          renderInput={(params: TextFieldProps) => (
            <TimePickerTextField hasError={hasError} {...params} />
          )}
        />
      </LocalizationProvider>
    </InputGroup>
  );
};

const TimePickerTextField: React.FC<TextFieldProps & { hasError: boolean }> = ({
  hasError,
  ...otherProps
}) => {
  delete otherProps.inputProps?.placeholder;

  return <StyledTextField hasError={hasError} {...{ ...otherProps, placeholder: "" }} />;
};
