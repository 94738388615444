import { CreateInstitutionDTO, CreateInstiutionUserDTO } from "types/dto";
import { InstitutionAnnounce } from "types/entity";
import { INSTITUTION_CONTRACT_TYPE, SERVICES } from "types/enum";
import { convertDateToISOString, convertISOStringToDate } from "../utils";

export interface InstitutionAnnounceDTO extends Omit<InstitutionAnnounce, "startDate" | "endDate"> {
  startDate: string;
  endDate?: string;
}

export const convertInstitutionAnnounceDTOToEntity = (
  institutionAnnounceDto: InstitutionAnnounceDTO
): InstitutionAnnounce => ({
  ...institutionAnnounceDto,
  startDate: new Date(institutionAnnounceDto.startDate),
  endDate: convertISOStringToDate(institutionAnnounceDto.endDate),
});

export const convertInstitutionAnnounceToDTO = (
  institutionAnnounce: InstitutionAnnounce
): InstitutionAnnounceDTO => ({
  ...institutionAnnounce,
  startDate: institutionAnnounce.startDate.toISOString(),
  endDate: convertDateToISOString(institutionAnnounce.endDate),
});

export interface CreateInstitutionAnnounceDTO {
  startDate: Date;
  endDate?: Date;
  startHour: string;
  endHour: string;
  numberOfProfiles: number;
  certificateId: number;
  hourSalary: number;
  contractType: INSTITUTION_CONTRACT_TYPE;
  additionalInfo?: string;
  hasBonus: boolean;
  hasSeniorityBonus: boolean;
  services: SERVICES[];
}

// LEAD_SOURCE.CONTACT,
export type CreateInstitutionLeadWithInstitutionAndAccountDTO = {
  institution: CreateInstitutionDTO & {
    user: CreateInstiutionUserDTO;
  };
};
