import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";
import { HiPhone } from "react-icons/hi";

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: ${isMobile ? "center" : "space-between"};
  align-items: center;

  height: 10vh;
  padding: ${({ theme }) => theme.extend.getSpacing(5)}
    ${({ theme }) => theme.extend.getSpacing(10)};
  font-size: ${({ theme }) => theme.extend.fontSize.large};
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0 10px rgba(82, 97, 100, 0.16);

  ${isMobile && `justify-content: 'center'`}
`;

export const Logo = styled.img`
  width: 220px;
`;

export const LogoContainer = styled.div`
  display: grid;
  text-align: center;
`;

export const PhoneContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-size: ${({ theme }) => theme.extend.fontSize.medium};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};

  svg {
    vertical-align: middle;
  }
`;

export const PhoneIcon = styled(HiPhone)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const PhoneText = styled.span`
  margin-left: 0.25rem;
  font-size: ${({ theme }) => theme.extend.fontSize.medium};
  font-weight: ${({ theme }) => theme.extend.fontWeight.regular};
`;

export const LinkContainer = styled.div`
  font-family: ${({ theme }) => theme.extend.fontFamily.main};
  font-weight: ${({ theme }) => theme.extend.fontWeight.large};
  font-size: ${({ theme }) => theme.extend.fontSize.medium};

  vertical-align: middle;
  margin-right: ${({ theme }) => theme.extend.getSpacing(2)};
  margin-top: ${({ theme }) => theme.extend.getSpacing(1)};
`;
