import { PATHS } from "constant";
import { FC } from "react";
import { BsBriefcase } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import { SideBarContainer, SideBarItem, SideBarLogo, SideBarText } from "./SideBar.style";

const MENU = [
  {
    icon: <FiSearch />,
    text: "Mes annonces",
    url: PATHS.MY_ANNOUNCES,
  },
  {
    icon: <BsBriefcase />,
    text: "Mes intervenants",
    url: PATHS.MY_PARTICIPANTS,
  },
];

export const SideBar: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const go = (url: string) => () => {
    history.push(url);
  };

  return (
    <SideBarContainer>
      {MENU.map(({ icon, text, url }) => (
        <SideBarItem key={text} onClick={go(url)} isActive={url === pathname}>
          <SideBarLogo>{icon}</SideBarLogo>
          <SideBarText>{text}</SideBarText>
        </SideBarItem>
      ))}
    </SideBarContainer>
  );
};
