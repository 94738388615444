import Autocomplete from "@mui/material/Autocomplete";
import { InputGroup } from "components/molecules";
import { FieldProps } from "formik";
import { useFormikInput } from "hook/useFormikInput";
import React from "react";
import { SERVICES, SERVICES_FR } from "types/enum";
import { StyledAutocompleteTextField } from "../CertificatePicker/CertificatePicker.style";
import { ChipDeleteIcon, ChipsContainer, StyledChip } from "./ServicePicker.style";

interface ServicesPickerProps {
  label?: string;
  withError?: boolean;
  required?: boolean;
}

export const ServicesPicker: React.FC<FieldProps & ServicesPickerProps> = ({
  field,
  form,
  label,
  withError,
  required,
}) => {
  const { value: services, error, hasError } = useFormikInput<SERVICES[]>(form, field);

  const handleDelete = (serviceToDelete: SERVICES) => () => {
    form.setFieldValue(
      field.name,
      services.filter((service) => service !== serviceToDelete)
    );
  };

  return (
    <>
      <InputGroup error={error} label={label} withError={withError} required={required}>
        <Autocomplete
          multiple
          value={services}
          onChange={(_event, value) => {
            form.setFieldValue(field.name, value);
          }}
          renderTags={() => <></>}
          options={Object.values(SERVICES)}
          getOptionLabel={(option) => SERVICES_FR[option]}
          renderInput={(params) => <StyledAutocompleteTextField hasError={hasError} {...params} />}
        />
      </InputGroup>
      <ChipsContainer data-testid="services-container">
        {services.map((service) => (
          <StyledChip
            label={SERVICES_FR[service]}
            onDelete={handleDelete(service)}
            key={service}
            deleteIcon={<ChipDeleteIcon data-testid={`services-delete-icon-${service}`} />}
          />
        ))}
      </ChipsContainer>
    </>
  );
};
