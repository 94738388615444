import axios from "axios";
import { AddressGouvData } from "types/dto";
import { Address } from "types/entity";

const apiAddress = axios.create({
  baseURL: "https://api-adresse.data.gouv.fr/",
});

export const getGeoCoord = async (query: string): Promise<Omit<Address, "id">> => {
  const { data: addresses } = await apiAddress.get<AddressGouvData>(`search/?q=${query}`);
  const addressData = addresses.features[0];

  return {
    gpsLng: addressData.geometry.coordinates[0],
    gpsLat: addressData.geometry.coordinates[1],
    city: addressData.properties.city,
    postCode: addressData.properties.postcode,
    address: addressData.properties.name,
  };
};
