export enum INSTITUTION_CONTRACT_TYPE {
  CDI = "cdi",
  CDD = "cdd",
  INTERNSHIP = "internship",
  TEMPORARY = "temporary",
}

export const CONTRACT_TYPES_FR = {
  [INSTITUTION_CONTRACT_TYPE.CDI]: "CDI",
  [INSTITUTION_CONTRACT_TYPE.CDD]: "CDD",
  [INSTITUTION_CONTRACT_TYPE.INTERNSHIP]: "Stage / Contrat d'Allocation d'Etudes (CAE)",
  [INSTITUTION_CONTRACT_TYPE.TEMPORARY]: "Vacation",
};
